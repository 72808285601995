<script lang="ts">
  export let timestamp: number;
  export let duration: number;
  export let title: string;
  export let description: string | undefined;
  export let location: string | undefined;

  import {toISOURL} from './dateutils';

  const startDateTime = toISOURL(timestamp);
  const endDateTime = toISOURL(timestamp + duration);
  const urlEncodedDescription = description || ''; // TODO
  const urlEncodedLocation = location || ''; // TODO
</script>

<a
  class="block border border-green-400 p-2"
  target="_blank"
  href={`https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${startDateTime}%2F${endDateTime}&details=${urlEncodedDescription}&location=${urlEncodedLocation}&text=${title}`}
>
  <span class="flex flex-row"
    ><svg
      class="w-6 h-6 mx-2"
      enable-background="new 0 0 128 128"
      version="1.1"
      viewBox="0 0 128 128"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      ><g clip-rule="evenodd" fill-rule="evenodd">
        <rect width="128" height="128" fill="none" />
        <path
          d="M27.585,64c0-4.157,0.69-8.143,1.923-11.881L7.938,35.648    C3.734,44.183,1.366,53.801,1.366,64c0,10.191,2.366,19.802,6.563,28.332l21.558-16.503C28.266,72.108,27.585,68.137,27.585,64"
          fill="#FBBC05"
        />
        <path
          d="m65.457 26.182c9.031 0 17.188 3.2 23.597 8.436l18.644-18.618c-11.361-9.891-25.927-16-42.241-16-25.328 0-47.096 14.484-57.519 35.648l21.569 16.471c4.97-15.086 19.137-25.937 35.95-25.937"
          fill="#EA4335"
        />
        <path
          d="m65.457 101.82c-16.812 0-30.979-10.851-35.949-25.937l-21.57 16.468c10.423 21.167 32.191 35.651 57.519 35.651 15.632 0 30.557-5.551 41.758-15.951l-20.474-15.828c-5.777 3.639-13.052 5.597-21.284 5.597"
          fill="#34A853"
        />
        <path
          d="m126.63 64c0-3.782-0.583-7.855-1.457-11.636h-59.72v24.727h34.376c-1.719 8.431-6.397 14.912-13.092 19.13l20.474 15.828c11.766-10.92 19.419-27.188 19.419-48.049"
          fill="#4285F4"
        />
      </g></svg
    >
    Google
  </span>
</a>

<script lang="ts">
  export let timestamp: number;
  export let duration: number;
  export let title: string;
  export let description: string | undefined;
  export let location: string | undefined;

  import {toOutlookISO} from './dateutils';

  const startDateTime = toOutlookISO(timestamp);
  const endDateTime = toOutlookISO(timestamp + duration);

  const urlEncodedDescription = description || ''; // TODO
  const urlEncodedLocation = location || ''; // TODO
</script>

<a
  class="block border border-green-400 p-2"
  target="_blank"
  href={`https://outlook.live.com/calendar/0/deeplink/compose?body=${urlEncodedDescription}&enddt=${endDateTime}&location=${urlEncodedLocation}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${startDateTime}&subject=${title}`}
>
  <span class="flex flex-row"
    ><svg class="w-6 h-6 mx-2" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
      <g>
        <title>background</title>
        <rect x="-1" y="-1" width="582" height="402" fill="none" />
      </g>
      <g>
        <title>Layer 1</title>
        <path
          d="m644.88 764.42v-271.72c19.329 13.255 37.371 25.627 55.413 37.996 16.194 11.101 18.919 11.045 35.633-0.064 92.48-61.468 184.98-122.92 277.48-184.36 2.803-1.861 5.698-3.585 9.774-6.141 0.322 4.208 0.777 7.36 0.777 10.514 0.035 118.22 0.089 236.44-7e-3 354.66-0.034 39.297-19.884 59.078-58.972 59.097-102.8 0.047-205.6 0.018-308.41 0.018-3.703-1e-3 -7.405-1e-3 -11.691-1e-3z"
          clip-rule="evenodd"
          fill="#0072c6"
          fill-rule="evenodd"
        />
        <path
          d="m645.62 216.85h10.489c108.56 0 217.12-9e-3 325.68 0.01 19.41 4e-3 34.427 11.871 40.529 30.528 2.68 8.197-0.047 12.205-6.669 16.641-84.095 56.307-168.01 112.88-251.98 169.38-11.994 8.072-24.149 15.92-35.93 24.292-6.448 4.579-12.154 4.828-18.844 0.521-19.187-12.357-38.738-24.161-57.786-36.721-3.179-2.095-6.486-6.876-6.505-10.434-0.337-63.505-0.176-127.01-0.094-190.52 5e-3 -0.712 0.412-1.423 1.109-3.701z"
          clip-rule="evenodd"
          fill="#0072c6"
          fill-rule="evenodd"
        />
        <path
          d="m596.72 1024c-199.08-41.667-397.39-83.177-596.44-124.84v-13.094c-1e-3 -252.51 0.076-505.03-0.273-757.54-0.016-10.461 2.793-14.09 13.038-16.049 159.15-30.403 318.22-61.241 477.31-91.971 35.081-6.777 70.167-13.528 106.37-20.507v1024zm-158.68-512.98c-0.402-41.613-6.094-80.035-26.656-114.85-16.6-28.107-39.024-49.44-71.278-58.626-62.755-17.872-122.84 11.521-152.41 75.036-18.947 40.696-22.994 83.874-19.337 128.08 3.169 38.303 14.261 73.688 38.958 103.96 49.093 60.169 138.44 60.569 188.18 0.776 32.939-39.589 41.886-86.711 42.538-134.37z"
          clip-rule="evenodd"
          fill="#0072c6"
          fill-rule="evenodd"
        />
        <path
          d="m232.92 511.94c-0.085-29.653 3.91-58.359 20.699-83.795 15.494-23.472 39.016-33.061 64.938-26.578 20.146 5.038 32.316 19.265 40.427 37.222 13.847 30.658 16.247 63.273 12.056 95.936-2.325 18.11-7.45 36.464-14.808 53.175-9.507 21.596-27.783 33.954-52.195 34.493-24.572 0.542-41.658-12.202-53.632-32.584-14.129-24.051-17.552-50.585-17.485-77.869z"
          clip-rule="evenodd"
          fill="#0072c6"
          fill-rule="evenodd"
        />
      </g>
    </svg>Outlook</span
  >
</a>
